body {
  margin: 0;
  font-family: Be+Vietnam+Pro !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assest/img/elipse.png');
  background-repeat: no-repeat;
  background-size: 1570px 800px;
  background-position: left top; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light{
  background-color: transparent !important;
  
}
a.nav-link{
  color: white !important;
}

button.custom-button.btn.btn-link{
  font-size: 40px !important;
  color: #37E2D5 !important;
  margin-top: -15px !important;
}
h1{
  color: white !important;
}

.custom-text{
  color: white;
  align-items: center;
}

.btn-primary{
  background-color: white !important;
  color: #6A4BFF !important;
  border: none !important;
}

.features{
  margin-top: 100px;
  
}

h5{
  font-weight: 700 !important;
  font-size: 36px !important;
  color: #0C0047 ;
}

.subtitle{
  color: #969696 !important;
}

.watch {
  margin-top: 100px;
  background-image: url('./assest/img/watch.svg');
  background-repeat: no-repeat !important;
  background-attachment: unset;
  padding: 200px 150px;
}

@media (max-width: 768px) {
  .watch {
    padding: 100px 75px; /* Örneğin, ekran 768 pikselden küçükse boyutları küçült */
  }
}

@media (max-width: 576px) {
  .watch {
    padding: 50px 25px; /* Ekran 576 pikselden küçükse daha fazla küçült */
  }
}


.btn-secondary{
  background-color: #6A4BFF !important;
  color: white !important;
  border: none !important;
}
ul {
  list-style-type: none;
}